import { t } from 'i18next';
import { appRoutesObj } from '../../app.paths';

export const getHeaderLinks = (pathname: string) => {
  return [
    // {
    //   index: 1,
    //   to: appRoutesObj.getBaseUrl(),
    //   name: t("components.Header.home"),
    //   isActive: pathname === appRoutesObj.getBaseUrl(),
    // },
    {
      index: 2,
      to: appRoutesObj.getBaseUrl() + '#features',
      name: t('components.Header.features'),
      isActive: pathname === appRoutesObj.getBaseUrl() + '#features',
    },
    {
      index: 3,
      to: appRoutesObj.getInvestUrl(),
      name: t('components.Header.investments'),
      isActive: pathname === appRoutesObj.getBanksUrl(),
    },
    {
      index: 4,
      to: 'https://blog.drahim.sa/',
      name: t('components.Header.blogs'),
      isActive: false,
    },
    // {
    //   index: 4,
    //   to: appRoutesObj.getBlogsUrl(),
    //   name: t("components.Header.blogs"),
    //   isActive: pathname === appRoutesObj.getBlogsUrl(),
    // },
  ];
};
